/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import {
    object, string, shape, bool, number,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import GraphqlSearchProducts from './GraphqlSearchProducts';
import CategoryPageSkeleton from '../../GraphqlSkeletonComponents/CategoryPageSkeleton/CategoryPageSkeleton';
import { GRAPHQL_ENV } from '../../../../gql';
import { getUserSubmittedProductFilterZipcode, getLocationType } from '../../../../../state/ducks/App/App-Selectors';
import { getBestSellersId } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useExperimentServiceAttributes from '../../../../helpers/experimentService/useExperimentServiceAttributes';

const styles = (theme) => ({
    siteContainer: {
        margin: '0 15px',
        maxWidth: '1400px',
        minWidth: '1000px',
        [theme.breakpoints.up(1430)]: {
            margin: '0 auto',
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: '100%',
            minWidth: '100%',
            margin: '0 auto',
        },
    },
    bottomButton: {
        textAlign: 'center',
        margin: '10px 0px 50px',
        '& a': {
            textDecoration: 'none',
            fontSize: 15,
        },
        [theme.breakpoints.down(600)]: {
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin: ' 0 auto 30px',
            flexBasis: 'auto',
        },
    },
    backToHome: {
        textDecoration: 'none',
        padding: '10px 30px',
        border: `2px solid ${theme.palette.common.black}`,
        textTransform: 'uppercase',
        borderRadius: 5,
        margin: 5,
        color: theme.palette.common.black,
        '&:hover': {
            background: theme.palette.backToHomeHoverBg,
        },
    },
    continueShopping: {
        textDecoration: 'none',
        padding: '12px 35px',
        textTransform: 'uppercase',
        borderRadius: 5,
        margin: 5,
        background: theme?.palette?.cms?.continueButtonBg || theme.palette.continueShoppingBg,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme?.palette?.cms?.continueButtonBg || theme.palette.continueShoppingHoverBg,
        },
    },
});
const shoppingUrl = {
    '1800flowers': '/bestsellingflowers',
    harryanddavid: '/h/gift-baskets-tower-boxes/all-occasion-gift-baskets',
    berries: '/best-selling-gifts-shm',
    thepopcornfactory: '/popcorn-tin-gifts',
    wolfermans: '/w/top-rated',
    stockyards: '/s/best-sellers',
    cheryls: '/buttercream-frosted-cookie-gifts',
    vitalchoice: '/v/best-sellers',
};
const GraphqlNoResultsContainer = ({
    brand, classes, userSubmittedZip, locationType, filterData, bestSellerId, searchTerm, pageNumber,
}) => {
    const { lowToHighBtn, highToLowBtn, sellingBtn } = filterData;
    const continueShoppingUrl = shoppingUrl[brand?.domain] || '/bestsellingflowers';
    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });
    // hard coding category id because category object from findCategoryByUrl needs to be updated with correct category id
    // brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", id:"18F-Best Selling Flowers-400215581", locale: "en-us", ${userSubmittedZip !== '' ? `, productOptions:{zipCode:"${userSubmittedZip}", locationType:"${locationType}"}` : ''}
    const CATEGORY_PRODUCT_QUERY = gql`
    query CategoryProductQuery${isGraphqlTargetingEnabled ? '($targeting: [Targeting])' : ''} {
        findCategoryById(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", id: "${bestSellerId || '18F-Best Selling Flowers-400215581'}" , locale: "en-us"
        ${isGraphqlTargetingEnabled ? ', targeting: $targeting' : ''}
        ${lowToHighBtn ? ',productOptions:{ orderBy: PRICE_ASC}' : ''}
        ${highToLowBtn ? ',productOptions:{ orderBy: PRICE_DESC}' : ''}
        ${sellingBtn ? ',productOptions:{ orderBy: BEST_SELLERS}' : ''}
        ${userSubmittedZip !== '' && !lowToHighBtn && !highToLowBtn && !sellingBtn ? `, productOptions:{zipCode:"${userSubmittedZip}", locationType:"${locationType}"}` : ''}) {
            products {
                id
                brandId
                image {
                    altText
                    name
                    snipeImagePath
                    snipe
                    path
                }
                productSkus {
                    id
                  }
                partNumber
                prices {
                    currency
                    type
                    value
                }
                skuPrices
                skuPriceRange {
                    sale {
                        value
                    }
                    retail {
                        value
                    }
                }
                name
                brand
                seo {
                    url
                }
                availability {
                    availabilityIndicator
                    deliveryDateType
                    displayEndDate
                    displayStartDate
                    earliestShipDate
                    latestShipDate
                    perishable
                    productDeliveryType
                    shipAlone
                    deliveryMessage
                }
            }
        }
    }
`;

    return (

        <Query query={CATEGORY_PRODUCT_QUERY} variables={isGraphqlTargetingEnabled ? { targeting } : {}} context={context}>
            {({ loading, error, data }) => {
                if (loading) {
                    // return <div style={{ height: '80vh' }} />; // prevent header and footer from snapping together
                    return <CategoryPageSkeleton />;
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: CATEGORY_PRODUCT_QUERY,
                        component: 'GraphqlNoResultsContainer.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: CATEGORY_PRODUCT_QUERY,
                        component: 'GraphqlNoResultsContainer.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                if (data && data.findCategoryById && data.findCategoryById.products) {
                    const products = data.findCategoryById.products;

                    return (
                        <>
                            <Grid className={classes.siteContainer}>
                                {products && products.length ? <GraphqlSearchProducts brand={brand} products={products} searchTerm={searchTerm} eventSubType="search" pageNumber={pageNumber} /> : null}
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} className={classes.bottomButton}>
                                    <Link to={continueShoppingUrl} className={classes.continueShopping}>
                                        Continue shopping
                                    </Link>
                                    <Link to="/" className={classes.backToHome}>
                                        Go to the home page
                                    </Link>
                                </Grid>
                            </Grid>
                        </>
                    );
                }
                return null;
            }}
        </Query>

    );
};

GraphqlNoResultsContainer.propTypes = {
    classes: object.isRequired,
    brand: object.isRequired,
    locationType: string.isRequired,
    userSubmittedZip: string.isRequired,
    filterData: shape({
        lowToHighBtn: bool,
        highToLowBtn: bool,
        sellingBtn: bool,
        giftBox: bool,
        localFlorist: bool,
    }),
    bestSellerId: string,
    searchTerm: string.isRequired,
    pageNumber: number,
};

GraphqlNoResultsContainer.defaultProps = {
    filterData: {},
    bestSellerId: '',
    pageNumber: 1,
};

const mapStateToProps = (state) => ({
    locationType: getLocationType(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    bestSellerId: getBestSellersId(state),
});

export default connect(mapStateToProps)(withStyles(styles)(GraphqlNoResultsContainer));
