/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import  React, { PureComponent, createRef } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import {
    object, func, bool, string, number,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { getFeatureFlags } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import CollectionProductCountUI from '../../../CollectionProductCountUI';
import { trackEvent as track } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    sortAndFilterContainer: {
        position: 'relative',
        marginLeft: 'auto',
        textAlign: 'right',
        [theme.breakpoints.up('md')]: {
            minWidth: 105,
        },
        [theme.breakpoints.down(599)]: {
            width: '100%',
            margin: '0',
        },
    },
    containerFilter: {
        [theme.breakpoints.down(599)]: {
            justifyContent: 'flex-end',
        },
    },

    filterBtn: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        fontStyle: 'normal',
        lineHeight: '16px',
        letterSpacing: '0.05em',
        position: 'relative',
        width: '168px',
        justifyContent: 'center',
        height: '42px',
        cursor: 'pointer',
        background: theme.palette.common?.white || '#ffffff',
        border: `1px solid ${theme.palette.upsellsTextColor || '#2F2F2F'}`,
        fontSize: theme.palette?.text?.fontSize18 || '18px',
        color: theme.palette.upsellsTextColor || '#2F2F2F',
        boxSizing: 'border-box',
        '& svg': {
            marginLeft: '10px',
            color: theme.palette.upsellsTextColor || '#2F2F2F',
        },
        [theme.breakpoints.down(599)]: {
            letterSpacing: 'inherit',
            width: '100%',
        },
        [theme.breakpoints.down(1025)]: {
            width: 'auto',
        },
    },
    buttonLabel: {
        textAlign: 'center',
        width: '100%',
    },

    filterContainerDialogFoodBox: {
        width: '100%',
        boxShadow: '0px 2px 3px -1px rgba(0,0,0,0.35)',
        background: theme.palette.colorPrimaryContrast,
        borderRadius: '0px',
    },
    collapsedContainer: {
        width: '100%',
    },
    filterTitle: {
        color: theme.palette.colorNeutral70,
        fontSize: theme.typography?.fontSize || '16px',
        fontWeight: 700,
        lineHeight: '20px',
        margin: '16px 0',
    },
    filterSearchBtn: {
        padding: '13px 25px',
        borderRadius: '0px',
        color: theme.palette.colorNeutral70,
        fontWeight: 700,
        textTransform: 'capitalize',
        fontSize: theme.palette?.text?.fontSize14 ||  '14px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            background: theme.palette.cms?.primary || theme.palette.colorPrimary,
            color: theme.palette.colorPrimaryContrast,
        },
        '&:active': {
            background: theme.palette.cms?.primary || theme.palette.colorPrimary,
            color: theme.palette.colorPrimaryContrast,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '8px 10px',
        },
    },
    textRight: {
        textAlign: 'right',
    },
    content: {
        padding: '15px 0px',
        fontSize: theme.palette.text?.fontSize14 || '14px',
        fontWeight: 700,
        color: theme.palette.colorNeutral70,
    },
    applyBtn: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        width: '100%',
        border: '2px solid',
        display: 'block',
        padding: '8px 0px',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: theme.palette?.text?.fontSize14 || '14px',
        fontWeight: 700,
        cursor: 'pointer',
    },
    btnBackground: {
        background: theme.palette.cms?.primary || theme.palette.colorPrimary,
        color: theme.palette.colorPrimaryContrast,
        '&:hover': {
            background: theme.palette.cms?.primary || theme.palette.colorPrimary,
            color: theme.palette.colorPrimaryContrast,
        },
    },
    applyButtonWrapper: {
        marginTop: '10px',
    },
    switch: {
        '& span': {
            '& span': {
                color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            },
        },
    },
    options: {
        margin: '0',
        textDecoration: 'none',
        listStyle: 'none',
        textAlign: 'left',
        padding: '0',
        '& li': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '10px',
            height: '40px',
            fontStyle: 'normal',
            color: theme.palette.upsellsTextColor || '#2F2F2F',
            lineHeight: '16px',
            '&:hover': {
                background: theme.palette.filterDropdownHoverColor || '#F5F5F5',
            },
            [theme.breakpoints.down(581)]: {
                paddingLeft: '8px',
            },
            [theme.breakpoints.down(415)]: {
                paddingLeft: '5px',
                fontSize: theme.typography?.fontSize || '16px',
            },
            [theme.breakpoints.down(599)]: {
                fontSize: theme.palette?.text?.fontSize14 || '14px',
            },
        },
        '& span': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down(581)]: {
                marginRight: '5px',
            },
        },
    },
    activeColor: {
        background: theme.palette.filterDropdownHoverColor || '#F5F5F5',
    },
    doneIcon: {
        fontSize: theme.palette?.text?.fontSize20 || '20px',
    },

    filterFacetV3Dialog: {
        position: 'absolute',
        right: 0,
        top: '45px',
        zIndex: 3,
        maxWidth: '190px',
        width: '168px',
        background: 'transparent',
        [theme.breakpoints.down(1025)]: {
            width: '188px',
        },
        [theme.breakpoints.down(581)]: {
            width: '100%',
        },
        [theme.breakpoints.down(599)]: {
            width: '126px',
        },
    },
    popUpFilterSlider: {
        position: 'static',
        maxWidth: '100%',
        width: '100%',
        '& $filterContainerDialogFoodBox': {
            boxShadow: 'none',
            background: 'inherit',
            '& ul': {
                padding: 0,
            },
        },
        '& $options': {
            borderLeft: '1px solid #eee',
            '& $activeColor': {
                background: theme.palette.common?.white || '#ffffff',
            },
            '& li': {
                fontFamily: 'Garamond, Times, serif',
                cursor: 'pointer',
                display: 'flex',
                fontSize: theme.palette?.text?.fontSize14 || '14px',
                alignItems: 'center',
                paddingLeft: '10px',
                marginBottom: '15px',
                fontWeight: 'normal',
                height: 'auto',
                lineHeight: 'normal',
                '&:hover': {
                    background: theme.palette.common?.white || '#ffffff',
                },
            },
        },
    },
    popUpsortAndFilterContainer: {
        maxWidth: '100%',
        width: '100%',
        borderTop: '1px solid #cdcdcd',
        padding: '15px 0 35px',
        '& $filterBtn': {
            padding: 0,
            width: '100%',
            boxShadow: 'none',
            border: 'none',
            fontWeight: '400',
            borderRadius: '4px',
            justifyContent: 'center',
            '@media screen and (max-width: 480px)': {
                fontSize: theme.typography?.fontSize || '16px',
                minWidth: '85px',
                padding: '0 15px',
            },
            '@media screen and (max-width: 340px)': {
                fontSize: theme.typography?.fontSize || '16px',
            },
        },
    },
    popUpsortAndFilterContainerFloral: {
        padding: '0px',
        [theme.breakpoints.down(599)]: {
            width: '100%',
            margin: '0px 0px 0px 10px',
        },
    },
    totalResults: {
        [theme.breakpoints.up(1025)]: {
            marginLeft: '30px',
        },
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography?.fontSize || '16px',
        lineHeight: '24px',
    },

    popSliderTitle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'Garamond, Times, serif',
        '& span': {
            fontSize: theme.palette?.text?.fontSize20 || '20px',
            [theme.breakpoints.down(481)]: {
                fontSize: theme.palette?.text?.fontSize14 || '14px',
            },
        },
    },
    popSliderPopular: {
        display: 'flex',
        alignItems: 'center',
    },
    sortByLabel: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        marginRight: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '700',
        fontSize: theme.typography.fontSize || '16px',
        lineHeight: '20px',
        letterSpacing: '0.02em',
        color: theme.palette?.upsellsTextColor || '#2F2F2F',
        '@media screen and (max-width: 350px)': {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
    },

    activeLabelColor: {
        fontSize: '16px !important',
        lineHeight: '24px',
        background: theme.palette.filterDropdownHoverColor || '#EFF3F3',
        fontWeight: 400,
        justifyContent: 'flex-start',
        paddingLeft: '5px',
        [theme.breakpoints.down(600)]: {
            fontSize: `${theme.palette?.text?.fontSize14 || '14px'} !important`,
        },
        [theme.breakpoints.down(1025)]: {
            fontFamily: 'LatoBold, Lato, Arial, Helvetica, sans-serif',
            justifyContent: 'center',
            background: theme.palette.filterDropdownHoverColor || '#ffffff',
        },
        '@media screen and (max-width: 350px)': {
            fontSize: `${theme.palette?.text?.fontSize13 || '13px'} !important`,
        },
    },
    filterSelectedLabel: {
        [theme.breakpoints.down(1025)]: {
            width: '188px',
        },
        [theme.breakpoints.down(599)]: {
            width: '126px',
        },
        '@media screen and (max-width: 350px)': {
            width: '120px',
        },
        '& span': {
            fontSize: '1rem',
            [theme.breakpoints.down(599)]: {
                fontSize: theme.palette?.text?.fontSize14 || '14px',
            },
        },
    },
});

class DesktopFilterDialogV3 extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toggleFilter: false,
            sellingBtn: true,
            highToLowBtn: false,
            lowToHighBtn: false,
            localFlorist: true,
            giftBox: true,
            buttonLabel: 'Best Sellers',
        };
        this.containerRef = createRef();
    }

    componentDidMount() {
        const { trackEvent } = this.props;
        const { buttonLabel } = this.state;

        if (typeof document !== 'undefined') {
            document.addEventListener('mousedown', this.handleClickOutside);
        }

        trackEvent({
            eventCategory: 'Collection Page',
            eventAction: 'Sort By',
            eventLabel: buttonLabel,
            eventName: 'sort_click',
            sortType: buttonLabel?.replace('Price: ', ''),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { trackEvent } = this.props;
        const { buttonLabel } = this.state;
        if (buttonLabel !== prevState.buttonLabel) {
            window.pageViewFired = true;  // fire trackEvent immediately once user change sort by on current collection page
            trackEvent({
                eventCategory: 'Collection Page',
                eventAction: 'Sort By',
                eventLabel: buttonLabel,
                eventName: 'sort_click',
                sortType: buttonLabel?.replace('Price: ', ''),
            });
            window.pageViewFired = false; // revert to default
        }
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    handleClickOutside = (event) => {
        if (this.containerRef && event?.target && this.containerRef?.current && !this.containerRef.current.contains(event.target)) {
            this.setState({
                toggleFilter: false,
            });
        }
    }

    toggleFilter = () => {
        const { toggleFilter } = this.state;
        this.setState({
            toggleFilter: !toggleFilter,
        });
    };

    renderSortOptions = () => {
        const {
            classes, setFilterOptions, isPopFilterSlider,
        } = this.props;
        const {
            toggleFilter, giftBox, highToLowBtn, sellingBtn, localFlorist, lowToHighBtn, buttonLabel,
        } = this.state;
        return (
            <Grid container className={`${classes.filterFacetV3Dialog} ${isPopFilterSlider ? classes.popUpFilterSlider : ''}`}>
                <Collapse in={toggleFilter} timeout={300} className={classes.collapsedContainer}>
                    <Paper className={classes.filterContainerDialogFoodBox} elevation={0}>
                        <Grid item xs={12}>
                            <ul className={classes.options}>
                                <li className={`${(buttonLabel && sellingBtn) ? classes.activeColor : ''}`}>
                                    <span
                                        role="presentation"
                                        onClick={() => {
                                            this.setState({
                                                sellingBtn: true,
                                                highToLowBtn: false,
                                                lowToHighBtn: false,
                                                buttonLabel: 'Best Sellers',
                                                localFlorist,
                                                giftBox,
                                                toggleFilter: !toggleFilter,
                                            });
                                            setFilterOptions({
                                                toggleFilter,
                                                giftBox,
                                                sellingBtn: true,
                                                highToLowBtn: false,
                                                lowToHighBtn: false,
                                                localFlorist,
                                            });
                                        }}
                                        className={classes.optionLink}
                                    >Best Sellers
                                    </span>
                                </li>
                                <li className={`${(buttonLabel && lowToHighBtn) ? classes.activeColor : ''}`}>
                                    <span
                                        role="presentation"
                                        onClick={() => {
                                            this.setState({
                                                sellingBtn: false,
                                                highToLowBtn: false,
                                                lowToHighBtn: true,
                                                buttonLabel: 'Price: Low to High',
                                                localFlorist,
                                                giftBox,
                                                toggleFilter: !toggleFilter,
                                            });
                                            setFilterOptions({
                                                toggleFilter,
                                                giftBox,
                                                sellingBtn: false,
                                                highToLowBtn: false,
                                                lowToHighBtn: true,
                                                localFlorist,
                                            });
                                        }}
                                        className={classes.optionLink}
                                    >Price: Low to High
                                    </span>
                                </li>
                                <li className={`${(buttonLabel && highToLowBtn) ? classes.activeColor : ''}`}>
                                    <span
                                        role="presentation"
                                        onClick={() => {
                                            this.setState({
                                                sellingBtn: false,
                                                highToLowBtn: true,
                                                lowToHighBtn: false,
                                                buttonLabel: 'Price: High to Low',
                                                localFlorist,
                                                giftBox,
                                                toggleFilter: !toggleFilter,
                                            });
                                            setFilterOptions({
                                                toggleFilter,
                                                giftBox,
                                                sellingBtn: false,
                                                highToLowBtn: true,
                                                lowToHighBtn: false,
                                                localFlorist,
                                            });
                                        }}
                                        className={classes.optionLink}
                                    >Price: High to Low
                                    </span>
                                </li>
                            </ul>
                        </Grid>
                    </Paper>
                </Collapse>
            </Grid>
        );
    }

    render() {
        const {
            classes, isPopFilterSlider, featureFlags, filterOn, styleLeftSidebar, productCount, leftRail,
        } = this.props;
        const {
            buttonLabel,
        } = this.state;
        // const isFlowerBrand = presentationFamily === 'flower';

        const expandButton = (
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none">
                <path d="M3.54348 1V13.5M3.54348 13.5L0.5 11M3.54348 13.5L6.58696 11" stroke="#2F2F2F" strokeLinecap="round" />
                <path d="M12.6739 13.5V1M12.6739 1L9.63043 3.5M12.6739 1L15.7174 3.5" stroke="#2F2F2F" strokeLinecap="round" />
            </svg>
        );
        const renderLabel = () => {
            if (isPopFilterSlider) {
                return (
                    <div className={classes.popSliderTitle}>
                        <span>Sort By</span>
                        <span className={classes.popSliderPopular}>{buttonLabel || 'Best Sellers'}{expandButton}</span>
                    </div>
                );
            }
            if (!isPopFilterSlider) {
                return <>{`${buttonLabel ? `${buttonLabel}` : 'Sort By'}`} {!buttonLabel ? expandButton : ''}</>;
            }

            return (<>sort &amp; filter</>);
        };
        return (
            (featureFlags['is-sort-on-search-temp'] && filterOn === 'Search') || filterOn !== 'Search') && (
            <>
                {featureFlags['is-collection-product-count-enabled'] ? <CollectionProductCountUI itemCount={productCount} styleClass={leftRail ? classes.totalResults : ''} spacer={(<div className={leftRail ? styleLeftSidebar : ''} />)} /> : ''}
                {featureFlags['is-sort-and-filter-collections-enabled'] ?  (
                    <div
                        ref={this.containerRef}
                        className={`${classes.sortAndFilterContainer} ${classes.sortF0ilterContainerFloral } ${isPopFilterSlider ? classes.popUpsortAndFilterContainer : ''} ${classes.sortFilterContainerFood}`}
                    >
                        <Grid container className={classes.containerFilter}>
                            {buttonLabel && (<div className={classes.sortByLabel}>Sort by</div>)}
                            <div className={`${classes.filterSelectedLabel}`}>
                                <span
                                    role="presentation"
                                    aria-hidden="true"
                                    onClick={() => this.toggleFilter()}
                                    className={`${classes.filterBtn} ${buttonLabel ? `${classes.activeLabelColor}` : ''}`}
                                    data-testid="filter-label"
                                >
                                    {renderLabel()}
                                </span>
                            </div>
                        </Grid>
                        {this.renderSortOptions()}
                    </div>
                ) : ''}
            </>
        );
    }
}

DesktopFilterDialogV3.propTypes = {
    classes: object.isRequired,
    setFilterOptions: func.isRequired,
    isPopFilterSlider: bool,
    featureFlags: object,
    filterOn: string,
    productCount: number,
    styleLeftSidebar: string,
    leftRail: bool,
    trackEvent: func.isRequired,
};
DesktopFilterDialogV3.defaultProps = {
    isPopFilterSlider: false,
    featureFlags: {},
    filterOn: '',
    productCount: 0,
    styleLeftSidebar: '',
    leftRail: false,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(DesktopFilterDialogV3);
